<template>
  <div class="login-body">
    <div class="login-head">
      粮情测温客户端
      <el-link class="fr" type="primary" href="#/version/list">版本更新</el-link>
    </div>
    <div class="login-content">
      <div class="login-content-left">
        <img src="../assets/img/login/发布公告.png" />
      </div>
      <div class="login-form">
        <p class="title">登录</p>
        <el-form ref="loginForm" :model="loginForm" :rules="rules" class="mgT20" size="small">
          <el-form-item prop="username">
            <el-input placeholder="请输入用户名" v-model="loginForm.username">
              <template slot="prepend"><i class="el-icon-user"></i></template>
            </el-input>
          </el-form-item>
          <el-form-item prop="passwd">
            <el-input type="password" placeholder="请输入密码" v-model="loginForm.passwd">
              <template slot="prepend"><i class="el-icon-unlock"></i></template>
            </el-input>
          </el-form-item>
          <el-form-item id="code" prop="validateCode">
            <el-input placeholder="验证码" v-model="loginForm.validateCode">
              <template slot="append">
                <div class="code-img" @click="changeCode">
                  <Sidentify ref="sidentify" :verifyCode="valiCode" />
                </div>
                <!-- <img class="code-img" src="a"/> -->
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loadingLogin" class="width-100" type="primary" icon="el-icon-help"
              @click="loginIn('loginForm')">登录
            </el-button>
          </el-form-item>
          <div>
            <div class="fl">
              <el-checkbox v-model="loginForm.remember">记住我</el-checkbox>
            </div>
            <!-- <div class="fr">
              <el-checkbox v-model="loginForm.free">{{$cache.TOKEN_EXPIRES}}天免登录</el-checkbox>
            </div> -->
            <div class="clearfix"></div>
          </div>
        </el-form>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="login-footer">
      <p> Copyright©粮情检测控制系统版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">豫ICP备19036036号-1</a></p>
      <br>
      <p>技术支持:郑州新硕电子科技有限公司@2019</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "login",
    components: {
      Sidentify: () => import("../components/Sidentify")
    },
    data() {
      // var validateCode = (rule, value, callback) => {
      //   if (value === '') {
      //     callback(new Error('请输入验证码'));
      //   } else if (value.toLowerCase() !== this.valiCode.toLowerCase()) {
      //     callback(new Error('验证码不正确'));
      //   } else {
      //     callback();
      //   }
      // };
      return {
        valiCode: "",
        loadingValiCode: false,
        loginForm: {
          username: "", //
          passwd: "",
          validateCode: "",
          remember: false,
          free: true
        },
        loadingLogin: false,
        rules: {
          username: [{
            required: true,
            message: '用户名不能为空',
            trigger: 'blur'
          }],
          passwd: [{
            required: true,
            message: '登录密码不能为空',
            trigger: 'blur'
          }],
          // validateCode: [{
          //   required: true,
          //   message: '验证码不能为空',
          //   trigger: 'blur'
          // }, {
          //   validator: validateCode,
          //   trigger: 'blur'
          // }]
        }
      }
    },
    created() {
      this.changeCode();
      let remember = this.$cookies.get(this.$cache.REMEMBER_ME) === 'true'
      this.loginForm.remember = remember
      if (remember) {
        this.loginForm.username = this.$cookies.get(this.$cache.USER_NAME)
        this.loginForm.passwd = this.$cookies.get(this.$cache.USER_PASS)
      }
    },
    methods: {
      //登录验证
      loginIn: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.doLogin();
          } else {
            return false;
          }
        });
      },
      //登录
      doLogin: function() {
        if (this.loadingLogin) return;
        this.loadingLogin = true;
        let than = this;

        this.$axios.Post(this.$api.LoginAuth, {
          "username": this.loginForm.username,
          "password": this.loginForm.passwd
        }).then((res) => {
          than.$cookies.set(than.$cache.TOKEN, res.data.token, {
            expires: than.$cache.TOKEN_EXPIRES
          });

          than.$cookies.set(than.$cache.REMEMBER_ME, this.loginForm.remember);

          if (this.loginForm.remember) {
            than.$cookies.set(than.$cache.USER_NAME, this.loginForm.username);
            than.$cookies.set(than.$cache.USER_PASS, this.loginForm.passwd);
          }

          than.$message({
            message: '登录成功，正在进入系统...',
            type: 'success',
            duration: 2000,
            onClose: function() {
              than.loadingLogin = false;
              than.$router.push({
                path: "/"
              }).catch(() => {});
            }
          });
        }).catch((res) => {
          if (res && res.errMsg) {
            than.$message({
              message: res.errMsg,
              type: 'error',
              duration: 2000
            });
          }
          than.loadingLogin = false;
        })

        // than.$cookies.set(than.$cache.TOKEN, "testToken", {
        //   expires: than.$cache.TOKEN_EXPIRES
        // });
        // this.$message({
        //   message: '登录成功，正在进入系统...',
        //   type: 'success',
        //   duration: 2000,
        //   onClose: function() {
        //     than.loadingLogin = false;
        //     than.$router.push({
        //       path: "/"
        //     }).catch(() => {});
        //   }
        // });
      },
      //切换验证码
      changeCode() {
        this.valiCode = this.$util.randomCoding(4)
      }
    }
  }
</script>

<style lang="less">
  .login {

    &-head {
      box-shadow: 0 2px 12px 0 #9e9e9e;
      height: 60px;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      background-color: rgb(255, 255, 255);
      line-height: 60px;
      padding: 0 20px;
      color: #424242;
      font-weight: 600;
    }

    &-body {
      position: relative;
      height: 100%;
      min-height: 520px;
      overflow: auto;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url(../assets/img/login-bg.jpg);
    }

    &-content {
      background-color: #fff;
      // margin-top: 50%;
      // margin-left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      height: 320px;
      width: 600px;

      &-left {
        width: 300px;
        height: 100%;
        float: left;
        text-align: center;
        line-height: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: scale-down;
          background-color: aliceblue;
        }
      }
    }

    &-footer {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      text-align: center;
      color: #fff;
      font-size: 12px;

      a {
        color: #9e9e9e;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .login-form {
    // background-color: #FFFFFF;
    width: 300px;
    float: left;
    box-sizing: border-box;
    height: 100%;
    padding: 20px 40px;
    // border-radius: 20px;
    // min-height: 340px;
    // padding: 30px;
    // position: absolute;
    // right: 30%;
    // top: 25%;

    .title {
      font-size: 22px;
      text-align: center;
      font-weight: 600;
      // padding: 10px 0;

      // i {
      //   color: #0174F5
      // }
    }

    .code-img {
      width: 100%;
      display: block;
      height: 30px;
      object-fit: cover;
    }
  }

  .el-input-group__append,
  .el-input-group__prepend {
    background-color: #ffffff;
    padding: 0 15px;
  }

  #code .el-input-group__append {
    padding: 0 !important;
    width: 80px;
    height: 30px;
  }
</style>
